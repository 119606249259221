import React from 'react'
import Countdown from 'react-countdown';
import { useTranslation, withTranslation } from 'react-i18next';

const ContestCountdown = ({startTime, endTime}) => {
    const { t } = useTranslation();

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const runningRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) return <span>{t('sidebar.finished')}</span>;

        if (days > 0) return <span>{t('sidebar.ends_in')}<br/>{days}д {zeroPad(hours, 2)}:{zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}</span>;
        return <span>{t('sidebar.ends_in')}<br/>{zeroPad(hours, 2)}:{zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}</span>;
    };

    const notStartedRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) return <Countdown date={endTime} key={Date.now()} renderer={runningRenderer} />;

        if (days > 0) return <span>{t('sidebar.starts_in')}<br/>{days}д {zeroPad(hours, 2)}:{zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}</span>;
        return <span>{t('sidebar.starts_in')}<br/>{zeroPad(hours, 2)}:{zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}</span>;
    };

    return (
        <div style={{ color: '#b8c7ce', textAlign: 'center', fontSize: '30px' }}>
            <Countdown date={startTime} key={Date.now()} renderer={notStartedRenderer}/>
        </div>
    )
}

export default withTranslation()(ContestCountdown)
