
const prod = {
    REST_URL: process.env.REACT_APP_URL+'/api',
    WS_URL: process.env.REACT_APP_URL+'/ws'
};

const dev = {
    REST_URL: 'https://algo.olimpiici.com/api',
    WS_URL: 'https://algo.olimpiici.com/ws'
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;

export const processor = "Intel(R) Pentium(R) CPU G4400T @ 2.90GHz";
export const os = "Ubuntu 22.04.4 LTS";
export const gcc = "g++ 11.4.0";

