import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import UserBase from './components/Layout/User/UserBase';
import AdminBase from './components/Layout/Admin/AdminBase';
import BasePage from './components/Layout/BasePage';
import { useAuth } from './AuthContext';
import AuthorBase from './components/Layout/Author/AuthorBase';
import SpectatorBase from './components/Layout/Spectator/SpectatorBase';
import ProctorBase from './components/Layout/Proctor/ProctorBase';
import CoordinatorBase from './components/Layout/Coordinator/CoordinatorBase';
import ScorerBase from './components/Layout/Scorer/ScorerBase';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props}/>;

const UserHome = lazy(() => import('./components/User/UserHome'));
const UserRules = lazy(() => import('./components/User/UserRules'));
const UserMonitoring = lazy(() => import('./components/User/UserMonitoring'));
const UserTask = lazy(() => import('./components/User/UserTask'));
const UserExamGroup = lazy(() => import('./components/User/UserExamGroup'));
const UserSubmission = lazy(() => import('./components/User/UserSubmission'));
const UserCommunication = lazy(() => import('./components/User/UserCommunication'));
const TaskPdf = lazy(() => import('./components/Competition/Tasks/TaskPdf'));
const UserStanding = lazy(() => import('./components/User/UserStanding'));
const SystemInfo = lazy(() => import('./components/User/SystemInfo'));

const AuthorHome = lazy(() => import('./components/Author/AuthorHome'));

const Proctor = lazy(() => import('./components/Proctor/Proctor'));
const CoordinatorHome = lazy(() => import('./components/Coordinator/CoordinatorHome'));
const CoordinatorComputers = lazy(() => import('./components/Coordinator/CoordinatorComputers'));
const CoordinatorCheck = lazy(() => import('./components/Coordinator/CoordinatorCheck'));
const CoordinatorCommunication = lazy(() => import('./components/Coordinator/CoordinatorCommunication'));
const Scorer = lazy(() => import('./components/Scorer/Scorer'));
const ScorerHome = lazy(() => import('./components/Scorer/ScorerHome'));
const ScorerTaskSubmissions = lazy(() => import('./components/Scorer/ScorerTaskSubmissions'));
const Arbiter = lazy(() => import('./components/Arbiter/Arbiter'));
const ArbiterHome = lazy(() => import('./components/Arbiter/ArbiterHome'));
const ArbiterTaskSubmissions = lazy(() => import('./components/Arbiter/ArbiterTaskSubmissions'));

const AdminHome = lazy(() => import('./components/Admin/AdminHome'));
const AdminTasks = lazy(() => import('./components/Competition/AdminTasks'));
const AdminTask = lazy(() => import('./components/Competition/AdminTask'));
const AdminTaskArchive = lazy(() => import('./components/Admin/TaskArchive'));
const AdminTaskMetadata = lazy(() => import('./components/Admin/TaskMetadata'));
const AdminTaskDetails = lazy(() => import('./components/Admin/TaskDetails'));
const AdminContests = lazy(() => import('./components/Admin/AdminContests'));
const AdminContest = lazy(() => import('./components/Admin/AdminContest'));
const AdminContestStanding = lazy(() => import('./components/Competition/ContestStanding'));
const AdminContestSubmissions = lazy(() => import('./components/Competition/AdminContestSubmissions'));
const AdminScoringSubmissions = lazy(() => import('./components/Competition/AdminScoringSubmissions'));
const AdminSubmission = lazy(() => import('./components/Competition/AdminSubmission'));
const AdminContestUsers = lazy(() => import('./components/Admin/AdminContestUsers'));
const AdminContestUser = lazy(() => import('./components/Competition/AdminContestUser'));
const AdminContestUserMonitoring = lazy(() => import('./components/Competition/AdminContestUserMonitoring'));
const AdminContestUsersCreate = lazy(() => import('./components/Admin/AdminContestUsersCreate'));
const AdminContestUsersUpload = lazy(() => import('./components/Admin/AdminContestUsersUpload'));
const AdminCommunication = lazy(() => import('./components/Admin/AdminCommunication'));

const Login = lazy(() => import('./components/Pages/Login'));
const Register = lazy(() => import('./components/Pages/Register'));
const Reg = lazy(() => import('./components/Pages/Reg'));
const Recover = lazy(() => import('./components/Pages/Recover'));
const Lock = lazy(() => import('./components/Pages/Lock'));
const NotFound = lazy(() => import('./components/Pages/NotFound'));
const Error500 = lazy(() => import('./components/Pages/Error500'));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
// const listofPages = [
//     '/login',
//     '/register',
//     '/recover',
//     '/lock',
//     '/notfound',
//     '/error500'
// ];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };
    const user = useAuth().user;
    const animationName = 'rag-fadeIn'

    if(!user.username) {
        return (
            <BasePage>
                <Suspense fallback={<PageLoader/>}>
                    <Switch location={location}>
                        <Route path="/login" component={waitFor(Login)}/>
		{/*process.env.REACT_APP_ALLOW_REGISTER === 'true' && <Route path="/register" component={waitFor(Register)}/>*/}
			<Route path="/reg" component={waitFor(Reg)}/>
                        <Route path="/recover" component={waitFor(Recover)}/>
                        <Route path="/lock" component={waitFor(Lock)}/>
                        <Route path="/notfound" component={waitFor(NotFound)}/>
                        <Route path="/error500" component={waitFor(Error500)}/>
                        <Route path="/public/standing/:aid(\d+)" component={waitFor(Error500)}/>
                        <Redirect to='/login'/>
                    </Switch>
                </Suspense>
            </BasePage>
        )
    }

    if (location.pathname.indexOf("/pdf")>-1) {
        return (
            <Suspense fallback={<PageLoader/>}>
                <Switch location={location}>
                    <Route path="/task/:tid(\d+)/pdf/:lang?" component={waitFor(TaskPdf)}/>
                </Switch>
            </Suspense>
        )
    }
        
    if (user.userrole === 'user') {
        return (
            <UserBase>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <Suspense fallback={<PageLoader/>}>
                            <div>
                                <Switch location={location}>
                                    <Route path="/home" component={waitFor(UserHome)}/>
                                    <Route path="/rules" component={waitFor(UserRules)}/>
                                    <Route path="/monitoring" component={waitFor(UserMonitoring)}/>
                                    <Route path="/task/:tid(\d+)/submission/:sid(\d+)" component={waitFor(UserSubmission)}/>
                                    <Route path="/task/:tid(\d+)/quiz/:qid(\d+)" component={UserExamGroup}/>
                                    <Route path="/task/:tid(\d+)" component={waitFor(UserTask)}/>

                                    <Route path="/communication" component={waitFor(UserCommunication)}/>
                                    <Route path="/standing" component={waitFor(UserStanding)}/>
                                    <Route path="/system" component={waitFor(SystemInfo)}/>

                                    <Redirect to="/home"/>
                                </Switch>
                            </div>
                        </Suspense>
                    </CSSTransition>
                </TransitionGroup>
            </UserBase>
        )
    }

    if (user.userrole === 'author') {
        return (
                <AuthorBase>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <div>
                                <Suspense fallback={<PageLoader/>}>
                                    <Switch location={location}>
                                    	<Route path="/home" component={waitFor(AuthorHome)}/>
                                    	<Route path="/task/:tid(\d+)/archive" component={waitFor(AdminTaskArchive)}/>
                                        <Route path="/task/:tid(\d+)/metadata" component={waitFor(AdminTaskMetadata)}/>
                                        <Route path="/task/:tid(\d+)/details" component={waitFor(AdminTaskDetails)}/>
                                        <Route path="/task/:tid(\d+)" component={waitFor(AdminTask)}/>
                                        <Route path="/tasks" component={waitFor(AdminTasks)}/>
                                        <Route path="/task/:tid(\d+)/submission/:sid(\d+)" component={waitFor(UserSubmission)}/>
                                        <Route path="/communication/:cname" component={waitFor(AdminCommunication)}/>
                                    	<Route path="/communication" component={waitFor(AdminCommunication)}/>
                                        <Route path="/contest/:cname" component={waitFor(AdminContest)}/>
                                        <Route path="/contests" component={waitFor(AdminContests)}/>
                                        <Route path="/submission/:sid(\d+)" component={waitFor(AdminSubmission)}/>
                                        <Route path="/submissions/:cname" component={waitFor(AdminContestSubmissions)}/>
                                        <Route path="/submissions" component={waitFor(AdminContestSubmissions)}/>
                                        <Route path="/standing/:cname" component={waitFor(AdminContestStanding)}/>
                                        <Route path="/standing" component={waitFor(AdminContestStanding)}/>
                                        <Route path="/users/:uname" component={waitFor(AdminContestUser)}/>
                                        <Route path="/submissions/:cname" component={waitFor(AdminContestSubmissions)}/>
                                        <Route path="/submissions" component={waitFor(AdminContestSubmissions)}/>
                                  	<Route path="/system" component={waitFor(SystemInfo)}/>
                                  	
                                        <Redirect to="/tasks" />
                                    </Switch>
                                </Suspense>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </AuthorBase>
            )
        }

    if (user.userrole === 'spectator') {
        return (
                <SpectatorBase>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <div>
                                <Suspense fallback={<PageLoader/>}>
                                    <Switch location={location}>
                                        <Route path="/standing/:cname" component={waitFor(AdminContestStanding)}/>
                                        <Route path="/standing" component={waitFor(AdminContestStanding)}/>
                                        <Route path="/users/:uname" component={waitFor(AdminContestUser)}/>

                                        <Redirect to="/standing" />
                                    </Switch>
                                </Suspense>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </SpectatorBase>
            )
    }

    if (user.userrole === 'coordinator') {
        return (
                <CoordinatorBase>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <div>
                                <Suspense fallback={<PageLoader/>}>
                                    <Switch location={location}>
                                        <Route path="/home" component={waitFor(CoordinatorHome)}/>
                                        <Route path="/certificates" component={waitFor(CoordinatorComputers)}/>
                                        <Route path="/check" component={waitFor(CoordinatorCheck)}/>
                                        <Route path="/communication" component={waitFor(CoordinatorCommunication)}/>

                                        <Redirect to="/home" />
                                    </Switch>
                                </Suspense>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </CoordinatorBase>
            )
    }

    if (user.userrole === 'scorer') {
        return (
                <ScorerBase>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <div>
                                <Suspense fallback={<PageLoader/>}>
                                    <Switch location={location}>
                                        <Route path="/task/:tid/submission/:snumber" component={waitFor(Scorer)}/>
                                        <Route path="/task/:tid" component={waitFor(ScorerTaskSubmissions)}/>
                                        <Route path="/" component={waitFor(ScorerHome)}/>

                                        <Redirect to="/" />
                                    </Switch>
                                </Suspense>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </ScorerBase>
            )
    }

    if (user.userrole === 'arbiter') {
        return (
                <ScorerBase>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <div>
                                <Suspense fallback={<PageLoader/>}>
                                    <Switch location={location}>
                                        <Route path="/submission/:sid" component={waitFor(Arbiter)}/>
                                        <Route path="/task/:tid" component={waitFor(ArbiterTaskSubmissions)}/>
                                        <Route path="/" component={waitFor(ArbiterHome)}/>

                                        <Redirect to="/" />
                                    </Switch>
                                </Suspense>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </ScorerBase>
            )
    }
    
    if (user.userrole === 'proctor') {
        return (
                <ProctorBase>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <div>
                                <Suspense fallback={<PageLoader/>}>
                                    <Switch location={location}>
                                        <Route path="/contest/:cname" component={waitFor(Proctor)}/>
                                        <Route path="/contest" component={waitFor(Proctor)}/>

                                        <Redirect to="/contest" />
                                    </Switch>
                                </Suspense>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </ProctorBase>
            )
    }

    if (user.userrole === 'admin') {
    return (
            <AdminBase>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader/>}>
                                <Switch location={location}>
                                    <Route path="/home" component={waitFor(AdminHome)}/>
                                    <Route path="/task/:tid(\d+)/archive" component={waitFor(AdminTaskArchive)}/>
                                    <Route path="/task/:tid(\d+)/metadata" component={waitFor(AdminTaskMetadata)}/>
                                    <Route path="/task/:tid(\d+)/details" component={waitFor(AdminTaskDetails)}/>
                                    <Route path="/task/:tid(\d+)" component={waitFor(AdminTask)}/>
                                    <Route path="/tasks" component={waitFor(AdminTasks)}/>
                                    <Route path="/task/:tid(\d+)/submission/:sid(\d+)" component={waitFor(UserSubmission)}/>
                                    <Route path="/communication/:cname" component={waitFor(AdminCommunication)}/>
                                    <Route path="/communication" component={waitFor(AdminCommunication)}/>
                                    <Route path="/contest/:cname" component={waitFor(AdminContest)}/>
                                    <Route path="/contests" component={waitFor(AdminContests)}/>
                                    <Route path="/standing/:cname" component={waitFor(AdminContestStanding)}/>
                                    <Route path="/standing" component={waitFor(AdminContestStanding)}/>
                                    <Route path="/submission/:sid(\d+)" component={waitFor(AdminSubmission)}/>
                                    <Route path="/submissions/:cname" component={waitFor(AdminContestSubmissions)}/>
                                    <Route path="/submissions" component={waitFor(AdminContestSubmissions)}/>
                                    <Route path="/scoring" component={waitFor(AdminScoringSubmissions)}/>
                                    <Route path="/users/create" component={waitFor(AdminContestUsersCreate)}/>
                                    <Route path="/users/upload" component={waitFor(AdminContestUsersUpload)}/>
                                    <Route path="/users/contest/:cname" component={waitFor(AdminContestUsers)}/>
                                    <Route path="/users/:uname/monitoring" component={waitFor(AdminContestUserMonitoring)}/>
                                    <Route path="/users/:uname" component={waitFor(AdminContestUser)}/>
                                    <Route path="/users" component={waitFor(AdminContestUsers)}/>
                                    <Route path="/system" component={waitFor(SystemInfo)}/>

                                    <Redirect to="/home"/>
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </AdminBase>
        )
    }

    return <Redirect to='/login'/>;
}

export default withRouter(Routes);
