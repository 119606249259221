import React from 'react';
import { /*withNamespaces,*/ Trans, withTranslation } from 'react-i18next';
import { Link, withRouter, useLocation } from 'react-router-dom';
import { /*Collapse,*/ Badge } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions/actions';

//import SidebarRun from '../Sidebar.run';

import ContestCountdown from '../ContestCountdown';
import Menu from './Menu.js';
import SidebarLogo from '../SidebarLogo';
import { useAuth } from '../../../AuthContext';
import { useContest } from '../../../ContestContext';
import { useState, useEffect } from 'react';
/*import ContestCountdown from '../ContestCountdown';
import { useContest } from '../../../ContestContext';
import { useRest } from '../../../RestContext';
import useAsync from '../../../useAsync';
import PageLoader from '../../Common/PageLoader';*/

/** Component to display headings on sidebar */
const SidebarItemHeader = ({item}) => (
    <li className="nav-heading">
        <span><Trans i18nKey={item.translate}>{item.heading}</Trans></span>
    </li>
)

/** Normal items for the sidebar */
const SidebarItem = ({item, isActive, update}) => {
    return (<li className={ isActive ? 'active' : '' }>
            <Link to={item.path} title={item.name} onClick={() => update(u => u+1)}>
                {item.label && !!item.label.value && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
                {item.icon && <em className={item.icon}></em>}
                <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
            </Link>
        </li>
    )
}

/** Build a sub menu with items inside and attach collapse behavior */
/*const SidebarSubItem = ({item, isActive, handler, children, isOpen}) => (
    <li className={ isActive ? 'active' : '' }>
        <div className="nav-item" onClick={ handler }>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </div>
        <Collapse isOpen={ isOpen }>
            <ul id={item.path} className="sidebar-nav sidebar-subnav">
                { children }
            </ul>
        </Collapse>
    </li>
)*/

/** Component used to display a header on menu when using collapsed/hover mode */
/*const SidebarSubHeader = ({item}) => (
    <li className="sidebar-subnav-header">{item.name}</li>
)*/

const AuthorSidebar = () => {
    const location = useLocation();
    const user = useAuth().user;
    //const userrole = user.userrole;
    
    // const { value: tasks } = useAsync(json, `tasks`, []);

    const startTimes = useContest().startTimes;
    const endTimes = useContest().endTimes;
    const activeContests = useContest().activeContests;
    const setActiveContests = useContest().setActiveContests;

    const unanswered = useContest().unansweredQuestions;

    const routeActive = (paths) => {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(p => location.pathname.indexOf(p) === 0)
    }

    const [update, setUpdate] = useState(0);
    useEffect(() => {
        setActiveContests(c => []);
    }, [update]);

    // if (!tasks) return <PageLoader />
    return (
        <aside className='aside-container'>
            <div className="aside-inner">
                <nav data-sidebar-anyclick-close="" className="sidebar">
                    <ul className="sidebar-nav">
                        <Link to="/">
                            <SidebarLogo />
                        </Link>

                        {activeContests.map(c => {
                            return <ContestCountdown key={Date.now()} startTime={startTimes.get(c)} endTime={endTimes.get(c)} />;
                        })}

                        <SidebarItemHeader item={Menu.items.menu} />
                        <SidebarItem isActive={routeActive(Menu.items.home.path)} item={Menu.items.home} update={setUpdate} />
                        {user.contest && <SidebarItem isActive={routeActive(Menu.items.contests.path)} item={Menu.items.contests} update={setUpdate} />}
                        <SidebarItem isActive={routeActive(Menu.items.tasks.path)} item={Menu.items.tasks} update={setUpdate} />
                        {user.contest && <SidebarItem isActive={routeActive(Menu.items.submissions.path)} item={Menu.items.submissions} update={setUpdate} />}
                        {(user.contest || user.extra_info.includes("spectator")) && <SidebarItem isActive={routeActive(Menu.items.standing.path)} item={Menu.items.standing} update={setUpdate} />}
                        
                        {user.contest && <SidebarItem isActive={routeActive(Menu.items.communication.path)}
                        item={{...Menu.items.communication, label: { value: unanswered, color: 'danger' }}} update={setUpdate} />}

                        <SidebarItemHeader item={Menu.items.documentation} update={setUpdate} />
                        <li>
                            <Link target="_blank" to="/docs/en/index.html" onClick={() => setUpdate(u => u+1)}>
                                <em className="fas fa-book"></em><span>C++ Документация</span>
                            </Link>
                        </li>
                        <SidebarItem isActive={routeActive(Menu.items.systemInfo.path)} item={Menu.items.systemInfo} update={setUpdate} />
                    </ul>
                </nav>
            </div>
        </aside>
    );
    
}

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(withRouter(AuthorSidebar)));
